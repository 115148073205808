import { useCourse } from '@hooks/course';
import { useTranslation } from 'next-i18next';
import { useTracking } from '@hooks/tracking';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TALENTS_BANK } from '@utils/talents/talentsBank';
import {
  Container,
  ContainerMenu,
  Label,
  ContainerList,
  BorderBottom,
} from './styles';
import BreadcrumbsComponent from '../Breadcrumbs';

interface IMenu {
  text: string;
  path: string;
}

const Menu: React.FC = ({ children }) => {
  const { course } = useCourse();
  const { track } = useTracking();
  const [routerChange, setRouterChange] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('common');

  const menuItems = useMemo(
    () => [
      {
        text: t('menu_home'),
        path: '/',
        testid: 'home-menu-lms',
      },
      {
        text: t('menu_lesson'),
        path: '/aulas',
        testid: 'lesson-menu-lms',
      },
      {
        text: t('menu_journey'),
        path: '/jornada',
        testid: 'journey-menu-lms',
      },
      {
        text: t('menu_schedule'),
        path: '/agenda',
        testid: 'schedule-menu-lms',
      },
      {
        text: t('menu_networking'),
        path: '/networking',
        testid: 'networking -menu-lms',
      },
      {
        text: t('menu_ranking'),
        path: '/classificacao',
        testid: 'ranking -menu-lms',
      },
      {
        text: t('menu_materials'),
        path: '/materiais',
        testid: 'materials-menu-lms',
      },
      {
        text: t('menu_certificate'),
        path: '/certificado',
        testid: 'certificate-menu-lms',
      },
      {
        text: t('menu_faq'),
        path: '/ajuda',
        testid: 'FAQ-menu-lms',
      },
    ],
    [t],
  );
  const menuItemsTwo = useMemo(
    () => [
      {
        text: t('menu_journey'),
        path: '/',
        testid: 'journey-menu-lms',
      },
      {
        text: t('menu_lesson'),
        path: '/aulas',
        testid: 'lesson-menu-lms',
      },
      {
        text: t('menu_schedule'),
        path: '/agenda',
        testid: 'schedule-menu-lms',
      },
      {
        text: t('menu_networking'),
        path: '/networking',
        testid: 'networking -menu-lms',
      },
      {
        text: t('menu_ranking'),
        path: '/classificacao',
        testid: 'ranking -menu-lms',
      },
      {
        text: t('menu_materials'),
        path: '/materiais',
        testid: 'materials-menu-lms',
      },
      {
        text: t('menu_certificate'),
        path: '/certificado',
        testid: 'certificate-menu-lms',
      },
      {
        text: t('menu_faq'),
        path: '/ajuda',
        testid: 'FAQ-menu-lms',
      },
    ],
    [t],
  );

  const menuItemsWithoutJourney = useMemo(
    () => [
      {
        text: t('menu_home'),
        path: '/',
        testid: 'home-menu-lms',
      },
      {
        text: t('menu_lesson'),
        path: '/aulas',
        testid: 'lesson-menu-lms',
      },
      {
        text: t('menu_schedule'),
        path: '/agenda',
        testid: 'schedule-menu-lms',
      },
      {
        text: t('menu_networking'),
        path: '/networking',
        testid: 'networking -menu-lms',
      },
      {
        text: t('menu_ranking'),
        path: '/classificacao',
        testid: 'ranking-menu-lms',
      },
      {
        text: t('menu_materials'),
        path: '/materiais',
        testid: 'materials-menu-lms',
      },
      {
        text: t('menu_certificate'),
        path: '/certificado',
        testid: 'certificate-menu-lms',
      },
      {
        text: t('menu_faq'),
        path: '/ajuda',
        testid: 'FAQ-menu-lms',
      },
    ],
    [t],
  );
  const menuItemsFour = useMemo(
    () => [
      {
        text: t('menu_talents'),
        path: '/talentos',
        testid: 'talents-menu-lms',
      },
      {
        text: t('menu_favorite'),
        path: '/favoritos',
        testid: 'talents-menu-lms',
      },
    ],
    [t],
  );

  const hiddenMenu = useMemo(() => {
    const pathNameSplit = router?.pathname.split('/');
    return (
      (pathNameSplit[3] === 'aulas' && pathNameSplit[4]) ||
      router?.pathname === '/' ||
      pathNameSplit[1] === 'minhas-compras'
    );
  }, [router?.pathname]);

  const getSelected = useCallback(
    (path: string) => {
      const pathSplit = router?.pathname.split('/')[3];
      return (
        pathSplit === path.replace('/', '') || (path === '/' && !pathSplit)
      );
    },
    [router?.pathname],
  );

  const isEnable = useCallback(
    (path: string) => {
      const normalizePath = {
        Agenda: 'schedule',
        Calendario: 'schedule',
        Schedule: 'schedule',
        Networking: 'networking',
        Materiais: 'materials',
        Materials: 'materials',
        Materiales: 'materials',
        Ranking: 'ranking',
        Feed: 'feed',
        FAQ: 'faq',
        Lesson: 'lesson',
        Aulas: 'lesson',
        Class: 'lesson',
        Certificate: 'certificate',
        Certificado: 'certificate',
        Journey: 'journey',
        Jornada: 'journey',
      };
      if (course?.sections?.ranking === undefined && path === 'Ranking') {
        return false;
      }
      const enable = course?.sections?.[normalizePath[path]]?.enabled;
      return enable === undefined ? true : enable;
    },
    [course?.sections],
  );

  const trackEventName = useCallback(
    (text: string) => {
      switch (text) {
        case t('menu_home'):
          track('Click home menu', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on home - lms', {});
          break;
        case t('menu_lesson'):
          track('Clicked on classes - lms', {});
          break;
        case t('menu_journey'):
          track('Click journey menu', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on journey– lms', {});
          break;
        case t('menu_schedule'):
          track('Clicked on schedule menu - lms', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on schedule– lms', {});
          break;
        case t('menu_networking'):
          track('Click menu networking - lms', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on networking– lms', {});
          break;
        case t('menu_materials'):
          track('Clicked on materials', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on materials– lms', {});
          break;
        case t('menu_certificate'):
          track('Click certificate menu', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on certificate– lms', {});
          break;
        case t('menu_faq'):
          track('Click help menu', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on FAQ– lms', {});
          break;
        case t('menu_talents'):
          track('Click talents bank menu', {
            courseSlug: course?.slug,
            titleCourse: course?.name,
          });
          track('Clicked on Talents bank – lms', {});
          break;
        default:
      }
    },
    [course?.name, course?.slug, t, track],
  );

  const onClicked = useCallback(
    // eslint-disable-next-line consistent-return
    (enable: boolean, menu: IMenu) => {
      if (!getSelected(menu.path)) {
        if (enable === false || routerChange) {
          return 0;
        }
        setRouterChange(true);

        trackEventName(menu?.text);
        router.push({
          pathname: `/curso/${course?.slug}${menu.path}`,
        });
      }
    },
    [getSelected, routerChange, trackEventName, router, course?.slug],
  );
  useEffect(() => {
    setRouterChange(false);
  }, [router.pathname]);

  const renderMenuItems = useMemo(() => {
    const menusEnable = [];
    const menusDisable = [];

    if (!course?.typeTemplate) {
      menuItemsWithoutJourney.forEach(menu => {
        const enable = isEnable(menu.text);
        const selected = getSelected(menu.path);
        const item = (
          <ContainerMenu
            key={menu.path}
            onClick={() => onClicked(enable, menu)}
            isEnable={enable}
            data-testid={menu.testid}
          >
            <Label isSelected={selected}>{menu.text}</Label>
            <BorderBottom isSelected={selected} />
          </ContainerMenu>
        );
        if (!enable) menusDisable.push(item);
        else menusEnable.push(item);
      });
    } else if (course?.typeTemplate !== 'disableHome') {
      menuItems.forEach(menu => {
        const enable = isEnable(menu.text);
        const selected = getSelected(menu.path);
        const item = (
          <ContainerMenu
            key={menu.path}
            onClick={() => onClicked(enable, menu)}
            isEnable={enable}
            data-testid={menu.testid}
          >
            <Label isSelected={selected}>{menu.text}</Label>
            <BorderBottom isSelected={selected} />
          </ContainerMenu>
        );
        if (!enable) menusDisable.push(item);
        else menusEnable.push(item);
      });
    } else if (
      (course?.typeTemplate === 'disableHome' &&
        course.id === TALENTS_BANK.ID_COURSE) ||
      course.id === TALENTS_BANK.ID_TECH_ACADEMY
    ) {
      menuItemsFour.forEach(menu => {
        const enable = isEnable(menu.text);
        const selected = getSelected(menu.path);
        const item = (
          <ContainerMenu
            key={menu.path}
            onClick={() => onClicked(enable, menu)}
            isEnable={enable}
            data-testid={menu.testid}
          >
            <Label isSelected={selected}>{menu.text}</Label>
            <BorderBottom isSelected={selected} />
          </ContainerMenu>
        );
        if (!enable) menusDisable.push(item);
        else menusEnable.push(item);
      });
    } else if (course?.typeTemplate === 'disableHome') {
      menuItemsTwo.forEach(menu => {
        const enable = isEnable(menu.text);
        const selected = getSelected(menu.path);
        const item = (
          <ContainerMenu
            key={menu.path}
            onClick={() => onClicked(enable, menu)}
            isEnable={enable}
            data-testid={menu.testid}
          >
            <Label isSelected={selected}>{menu.text}</Label>
            <BorderBottom isSelected={selected} />
          </ContainerMenu>
        );
        if (!enable) menusDisable.push(item);
        else menusEnable.push(item);
      });
    }

    return [...menusEnable, ...menusDisable];
  }, [
    course?.id,
    course?.typeTemplate,
    getSelected,
    isEnable,
    menuItems,
    menuItemsFour,
    menuItemsTwo,
    menuItemsWithoutJourney,
    onClicked,
  ]);

  return (
    <>
      {!hiddenMenu && (
        <Container>
          <BreadcrumbsComponent slug={course?.name} />
          <ContainerList>{renderMenuItems}</ContainerList>
        </Container>
      )}
      {children}
    </>
  );
};

export default Menu;
